<template>
  <a-layout>
    <a-layout-header :style="{ position: 'fixed', zIndex: 999, width: '100%' }">
      <div class="nav-header-main">
        <div class="nav-header-left">
          <div class="logo">
            <a href="">
              <img src="@/assets/images/logo.png" alt="" />
              <span class="title"> 测试模板</span>
            </a>
          </div>
        </div>
        <!-- theme="dark" -->
        <div>
          <a-menu
            v-model:selectedKeys="selectedKeys"
            mode="horizontal"
            :style="{ lineHeight: '64px' }"
          >
            <a-menu-item key="1">
              <template #icon> <appstore-outlined /> </template>
              <router-link to="/"> 首页 </router-link>
            </a-menu-item>
            <a-menu-item key="2">
              <template #icon> <appstore-outlined /> </template>
              <router-link to="/resources">资源</router-link>
            </a-menu-item>
            <a-menu-item key="3">
              <template #icon> <appstore-outlined /> </template>
              <router-link to="/wish">世界</router-link>
            </a-menu-item>
          </a-menu>
        </div>
        <div></div>
      </div>
    </a-layout-header>
    <a-layout class="layout-content" style="margin-left: 200px">
      <a-layout-sider
        class="ant-pro-sider-fixed"
        width="200"
        style="
          max-height: 100vh;
          background: #fff;
          padding-top: 74px;
          overflow-y: auto;
        "
      >
        <div style="flex: 1 1 0%; overflow: hidden auto">
          <a-menu
            v-model:selectedKeys="selectedKeys2"
            v-model:openKeys="openKeys"
            mode="inline"
            :style="{ height: '100%', borderRight: 0 }"
          >
            <a-menu-item key="1">
              <template #icon>
                <user-outlined />
              </template>
              Navigation One
            </a-menu-item>
            <a-menu-item key="2">
              <template #icon>
                <user-outlined />
              </template>
              Navigation Two
            </a-menu-item>
          </a-menu>
        </div>
      </a-layout-sider>
      <a-layout style="padding: 0 0px 0px">
        <a-layout-content style="margin-top: 64px">
          <router-view />
        </a-layout-content>
        <a-layout-footer :style="{ textAlign: 'center' }">
          Ant Design ©2018 Created by Ant UED
        </a-layout-footer>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import { AppstoreOutlined, UserOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
export default defineComponent({
  components: {
    AppstoreOutlined,
    UserOutlined,
  },
  setup() {
    return {
      list: [1, 2, 3, 4, 5, 6, 5, 4],
      selectedKeys1: ref(["2"]),
      selectedKeys2: ref(["1"]),
      collapsed: ref(false),
      openKeys: ref(["sub1"]),
    };
  },
});
</script>
<style lang="less"  scoped>

.ant-layout {
  height:100%
  // height: calc(~"100% - 65px");
}
.layout-content {
  height: 100%;

  // height: calc(~"100% - 65px");
}
.layout-content {
  overflow: auto;
}
.ant-layout-content{
  overflow: auto;
}

.ant-pro-sider-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
}
</style>